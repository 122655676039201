@import '@/styles/artifacts.scss';



































































































































































































































































































































































































































































































.placeholder-cell {
  text-align: center;
  font-weight: fontWeight(regular-bold);
  font-size: fontSize(fs-150);
}
.chart-cell {
  align-self: stretch;
  height: 100%;
}
.chart {
  width: 100%;
  height: 100%;
}
.chart-style-switcher {
  padding-top: 15px;
  padding-right: 15px;
}
