@import '@/styles/artifacts.scss';
























.link {
  display: block;
  box-sizing: border-box;
  height: 38px;
  width: 50px;
  padding: 2px 8px;
}
.icon-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
.link.active .icon-wrapper {
  background-color: colorVodafone(dark-red);
}
.link:hover .icon-stroke {
  stroke: colorVodafone(white);
}
.link:hover .icon-fill {
  fill: colorVodafone(white);
}
.push-down {
  margin-top: auto;
}
