@import '@/styles/artifacts.scss';























.core-emphasized-tree-node {
  margin-top: 8px;
  color: colorVodafone(black);
}
.tree-node {
  display: flex;
  align-items: center;
  font-size: fontSize(fs-100);
  line-height: lineHeight(lh-50);
  font-weight: 700;
}
.tree-node.active,
.tree-node:not(.disabled):hover {
  color: colorVodafone(vodafone-red);
}
.name {
  flex: 1 0 0;
  @include textOverflow();
}
.icon {
  width: 8px;
  margin-left: 8px;
}
