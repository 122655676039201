@import '@/styles/artifacts.scss';























































.indicators {
  margin-top: 100px;
}
