@import '@/styles/artifacts.scss';




































.core-tree-node-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.indent {
  padding-left: 0.5em;
}
