@import '@/styles/artifacts.scss';








































.indicator {
  display: inline-block;
}
