@import '@/styles/artifacts.scss';














































.flex {
  display: flex;
  flex-wrap: wrap;
  margin: -10px -10px;
}
.flex.nowrap {
  flex-wrap: nowrap;
}
.flex.stretch {
  width: calc(100% + 20px);
  height: calc(100% + 20px);
}
