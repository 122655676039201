@import '@/styles/artifacts.scss';














.wrapper {
  box-sizing: border-box;
  height: 100%;
  width: 50px;
  padding: 18px 0;
  background: colorVodafone(vodafone-red);
}
.list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  height: 100%;
}
