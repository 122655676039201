@import '@/styles/artifacts.scss';






















.link {
  display: flex;
  align-items: center;
  color: colorVodafone(dark-grey);
}
.link:hover {
  color: colorVodafone(vodafone-red);
}
.icon {
  flex: 0 0 auto;
  width: 13px;
  height: 13px;
  margin-right: 10px;
}
.label {
  font-size: fontSize(fs-base);
  line-height: lineHeight(lh-xl);
  @include textOverflow();
}
