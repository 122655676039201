@import '@/styles/artifacts.scss';

















.wrapper {
  box-sizing: border-box;
  height: 76px;
  display: flex;
  align-items: center;
  background: colorVodafone(white);
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
}
.home {
  flex: 0 0 auto;
}
.logo {
  margin: 2px 10px 0;
  width: 28px;
}
.title {
  margin-left: 20px;
  color: colorVodafone(vodafone-red);
  line-height: lineHeight(lh-reset);
  max-width: 100px;
  font-weight: 600;
}
.end {
  flex: 0 1 auto;
  margin-left: auto;
  height: 100%;
}
