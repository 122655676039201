@import '@/styles/artifacts.scss';



















.not-allowed-text {
  text-align: center;
  color: colorVodafone(vodafone-red);
  font-size: 3rem;
}
