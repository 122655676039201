@import '@/styles/artifacts.scss';










































.ui-clickable {
  text-decoration: inherit;
  color: inherit;
  cursor: pointer;
}

.ui-clickable.disabled {
  cursor: inherit;
}
