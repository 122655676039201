@import '@/styles/artifacts.scss';


































.heating {
  font-size: fontSize(fs-300);
  line-height: lineHeight(lh-100);
}
.RED {
  color: colorVodafone(vodafone-red);
}
.BLUE {
  color: colorVodafone(aqua-blue);
}
