@import '@/styles/artifacts.scss';































.on {
  color: colorVodafone(digital-green);
}
.off {
  color: colorVodafone(digital-red);
}
