@import '@/styles/artifacts.scss';





































































.switch {
  margin-bottom: 5px;
  text-align: center;
}
.switch ::v-deep .v-switch-core {
  background: #c5c5c5;
}
.switch ::v-deep .toggled .v-switch-core {
  background: colorVodafone(digital-green);
}

.hidden {
  visibility: hidden;
}
