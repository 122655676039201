@import '@/styles/artifacts.scss';



































































.values {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
  padding: 0 16px;
  height: 100%;
}
.value {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 38px;
  width: 56px;
  height: 56px;
  background-color: colorVodafone(vodafone-white);
  text-align: center;
  line-height: 48px;
  position: absolute;

  &:nth-of-type(1) {
    top: 5%;
    left: 10%;
    color: colorVodafone(black);
  }

  &:nth-of-type(2) {
    top: 5%;
    right: 10%;
    color: colorVodafone(black);
  }

  &:nth-of-type(3) {
    bottom: 0;
    left: 10%;
    color: colorVodafone(black);
  }

  &:nth-of-type(4) {
    bottom: 0;
    right: 10%;
    color: colorVodafone(black);
  }
}
.heating {
  font-size: fontSize(fs-150);
  line-height: lineHeight(lh-50);
  color: inherit;
}
.place {
  font-size: fontSize(fs-50);
  line-height: lineHeight(lh-50);
  color: colorVodafone(black);
}
.data {
  margin-top: 1.2rem;
  margin-bottom: 0.8rem;

  p {
    margin-top: 0;
    margin-bottom: 0.4rem;
  }
}
