@import '@/styles/artifacts.scss';

































































































































$headerHeight: 76px;
$appBarWidth: 50px;
$treeNodeBarWidth: 240px;
// ease-out-cubic
$ease: cubic-bezier(0.19, 1, 0.22, 1);
$easeDuration: 0.5s;

.layout-default {
  box-sizing: border-box;
  padding: $headerHeight 0 0 #{$appBarWidth + $treeNodeBarWidth};
  min-height: 100vh;
  flex-flow: row wrap;
  align-items: stretch;
  transition: padding $easeDuration $ease;
}

.layout-default.tree-node-bar-disabled {
  padding-left: $appBarWidth;
}

.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: $headerHeight;
  z-index: 100;
}

.app-bar {
  position: fixed;
  left: 0;
  top: $headerHeight;
  width: $appBarWidth;
  height: calc(100% - #{$headerHeight});
  overflow: hidden;
  z-index: 90;
}

.tree-node-bar {
  position: fixed;
  left: $appBarWidth;
  top: $headerHeight;
  width: $treeNodeBarWidth;
  height: calc(100% - #{$headerHeight});
  overflow: hidden;
  z-index: 80;
  transition: left $easeDuration $ease;
}

.main {
  display: grid;
  position: relative;
  grid-auto-flow: column;
  grid-template-columns: minmax(0, 1fr);
  box-sizing: border-box;
  padding: 20px;
  min-height: calc(100vh - #{$headerHeight});
  min-width: 70%;

  @media screen and (min-width: 2000px) {
    grid-template-columns: minmax(0, 0.8fr);
  }

  @media screen and (min-width: 3000px) {
    grid-template-columns: minmax(0, 0.5fr);
    min-width: 60%;
    max-width: 72%;
  }

  margin-right: auto;
}

.cropped-screen {
  max-width: 88%;
}

.main.single-page {
  margin-bottom: 0;
}

.tree-node-bar-enter-active,
.tree-node-bar-leave-active {
  transition: left $easeDuration $ease;
}
.tree-node-bar-enter,
.tree-node-bar-leave-to {
  left: #{$appBarWidth - $treeNodeBarWidth};
}

.layout-enter-active,
.layout-leave-active {
  transition: opacity 300ms;
}
.layout-enter,
.layout-leave-to {
  opacity: 0;
}

.main-enter-active,
.main-leave-active {
  transition: opacity 300ms;
}
.main-enter,
.main-leave-to {
  opacity: 0;
}

.scroll-to-top {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 40px;
}
.scroll-to-top.scrolled {
  display: block;
}
.scroll-to-top-panel {
  display: flex;
  align-items: center;
  justify-content: center;
}

.resizer {
  flex: none;
  cursor: col-resize;
  min-width: 15%;
  max-width: 30%;
  // for xxl screen and larger
  @media screen and (min-width: 2000px) {
    min-width: 300px;
    max-width: 25%;
  }

  @media screen and (min-width: 3000px) {
    max-width: 15%;
  }
}

.resizer:hover {
  border-right: 2px solid #e60000;
  box-shadow: 0 1px 4px 1px #e60000;
}
