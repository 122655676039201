@import '@/styles/artifacts.scss';



























.icon {
  width: 28px;
  height: 35px;
}

.icon .icon-fill {
  fill: colorVodafone(health-ok);
}
.alerts .icon-fill {
  fill: colorVodafone(health-critical);
}
