@import '@/styles/artifacts.scss';




















































.user-toolbar {
  position: relative;
  box-shadow: border-box;
  padding: 0 35px;
  max-width: 200px;
  height: 100%;
}
.user-info {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  z-index: 110;
}
.text-overflow {
  @include textOverflow();
}
.name {
  font-size: fontSize(fs-50);
  line-height: lineHeight(lh-100);
}
.email {
  font-size: fontSize(fs-50);
  line-height: lineHeight(lh-100);
  color: colorVodafone(vodafone-red);
}
.user-toolbar-menu {
  position: absolute;
  top: -10000px;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.13);
  border-radius: 4px;
  padding: 96px 35px 30px 35px;
  width: max-content;
  min-width: 100%;
  z-index: 100;
  background-color: colorVodafone(white);
  opacity: 0;
  transition: opacity 0.3s linear, top 1ms 0.3s linear;
}

.toolbar-link {
  margin-bottom: 0.5em;
}

.user-toolbar-menu.open {
  top: 0;
  opacity: 1;
  transition: opacity 0.3s linear, top 1ms linear;
}
