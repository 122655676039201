@import '@/styles/artifacts.scss';






























.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.headline {
  margin: 0;
  text-align: center;
  font-size: fontSize(fs-150);
  line-height: lineHeight(lh-25);
  font-weight: fontWeight(regular);
  @include textOverflow();
}
.description {
  margin: 5px 0 0;
  color: colorVodafone(grey);
  text-align: center;
  font-size: fontSize(fs-base);
  line-height: lineHeight(lh-50);
  @include textOverflow();
}
.map {
  margin: auto -18px -18px;
  position: relative;
  height: 102px;
  overflow: hidden;
  border-radius: 0 0 3px 3px;
}
.arrow {
  position: absolute;
  right: 20px;
  bottom: 32px;
  text-align: right;
}
