@import '@/styles/artifacts.scss';



































































































































.arrow {
  height: 16px;
  width: 16px;
}
