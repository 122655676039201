@import '@/styles/artifacts.scss';











































.values {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  padding: 0 16px;
  height: 100%;
}
.value {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 38px;
  margin-bottom: 16px;
  width: 60px;
  height: 60px;
  background-color: colorVodafone(vodafone-white);
  text-align: center;
}
.temperature {
  left: 10px;
  color: colorVodafone(vodafone-red);
  line-height: 60px;
  font-size: fontSize(fs-300);
}
.humidity {
  right: 10px;
  color: colorVodafone(aqua-blue);
  line-height: 60px;
  font-size: fontSize(fs-300);
}
.voc {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;

  .voc-label {
    color: colorVodafone(dark-grey);
    font-size: fontSize(fs-50);
  }
  .voc-value {
    color: colorVodafone(digital-green);
    font-size: fontSize(fs-200);
  }
}
