@import '@/styles/artifacts.scss';



































.search {
  position: relative;
}
.icon {
  height: 20px;
  margin-right: 10px;
  position: absolute;
  right: 5px;
  top: 15px;
}
