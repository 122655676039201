@import '@/styles/artifacts.scss';


















.app-banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  margin: 0 0 20px;
  background-color: #e60000;
  color: white;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.13);
  border-radius: 4px;
  box-sizing: border-box;
  .message {
    font-size: 1.4rem;
    margin-bottom: 20px;
  }
  img {
    display: block;
    width: 76px;
    padding: 4px 0 0;
  }
}
