@import '@/styles/artifacts.scss';



























.news-item:not(:last-child) {
  margin-bottom: 16px;
}
.date {
  font-size: fontSize(fs-base);
  font-weight: fontWeight(regular);
  margin-right: 16px;
}
.headline {
  margin: 0;
  font-size: fontSize(fs-150);
  font-weight: fontWeight(regular);
}
.content {
  margin: 0;
}
