@import '@/styles/artifacts.scss';













.states {
  display: flex;
  justify-content: space-between;
}
