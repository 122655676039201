@import '@/styles/artifacts.scss';























.core-tree-node-bar {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: colorVodafone(white);
}
.tree {
  margin-top: 20px;
  padding: 0 5px;
  color: colorVodafone(grey);
}
