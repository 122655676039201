@import '@/styles/artifacts.scss';






























.content {
  text-align: center;
}
.name {
  margin-bottom: 5px;
}
