@import '@/styles/artifacts.scss';
























.container {
  width: 33%;
}
.image {
  width: 25px;
  height: 25px;
  display: block;
  margin: 0 auto;
}
.label {
  margin-top: 5px;
  font-size: fontSize(fs-50);
  line-height: lineHeight(lh-reset);
  text-align: center;
}
.push-down {
  margin-top: 27px;
}
