@import '@/styles/artifacts.scss';










































































.core-tree-node {
  margin-top: 2px;
  color: colorVodafone(grey);
}
.tree-node {
  display: block;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tree-node::before {
  content: '+';
  display: inline-block;
  margin-left: 5px;
  width: 0.75em;
}
.tree-node.open::before {
  content: '-';
}
.tree-node.active,
.tree-node:not(.disabled):hover {
  color: colorVodafone(vodafone-red);
}
