@import '@/styles/artifacts.scss';






























.list {
  position: fixed;
  bottom: 5vh;
  right: 5vw;
  z-index: 100000;
  transition: opacity 5s linear;
}
.message {
  position: relative;
  font-size: fontSize(fs-base);
  border: 1px solid;
  border-radius: 3px;
  padding: 10px 20px 10px 45px;
  border-color: colorVodafone(aqua-blue);
  border-left: 5px solid colorVodafone(aqua-blue);
  background-color: colorVodafone(white);
  margin-top: 10px;
  z-index: 100000;
  animation: fadeSlideIn ease 0.7s;

  @keyframes fadeSlideIn {
    0% {
      right: -100vw;
      opacity: 0;
    }
    100% {
      right: 0vw;
      opacity: 1;
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 45px;
    height: 45px;
    mask: 10px center/25px auto no-repeat;
    background-color: colorVodafone(aqua-blue);
    mask-image: url('../../../../assets/images/toaster/info.svg');
  }
  &.error {
    border-color: colorVodafone(vodafone-red);
    &::after {
      background-color: colorVodafone(vodafone-red);
      // TODO: currently the exact icon is not available as in https://cdn-assets-eu.frontify.com/local/vodafone/eyJwYXRoIjoiXC9wdWJsaWNcL3VwbG9hZFwvc2NyZWVuc1wvMlwvMjc1OGVhNjA1MTdmNTMyNWUxOTljNTY5Y2ZiYmYwYmQtMTU2NTI3MDE1My5zdmcifQ:vodafone:YDsWUIaEn3RPaa2C234SPA_q6PIngZt_Bj3uBcNCQlU?width=2400
      mask-image: url('../../../../assets/images/toaster/exclamation-mark.svg');
    }
    .close::before,
    .close::after {
      background-color: colorVodafone(vodafone-red);
    }
  }
  &.warning {
    border-color: colorVodafone(fresh-orange);
    &::after {
      background-color: colorVodafone(fresh-orange);
      mask-image: url('../../../../assets/images/toaster/warning.svg');
    }
    .close::before,
    .close::after {
      background-color: colorVodafone(fresh-orange);
    }
  }
  &.success {
    border-color: colorVodafone(digital-green);
    &::after {
      background-color: colorVodafone(digital-green);
      mask-image: url('../../../../assets/images/toaster/smiley.svg');
    }
    .close::before,
    .close::after {
      background-color: colorVodafone(digital-green);
    }
  }
  .headline {
    font-weight: fontWeight(regular-bold);
    font-size: fontSize(fs-100);
  }
  .close {
    position: absolute;
    right: 6px;
    top: 6px;
    width: 9px;
    height: 9px;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
    &::before,
    &::after {
      position: absolute;
      left: 4px;
      content: ' ';
      height: 10px;
      width: 1px;
      background-color: colorVodafone(aqua-blue);
    }
    &::before {
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
    }
  }
}
