@import '@/styles/artifacts.scss';
































.menu-container {
  position: relative;
  .menu-icon {
    width: 16px;
    height: 16px;
    cursor: pointer;
    float: right;
  }

  .dropdown {
    position: absolute;
    top: 20px;
    right: 0px;
    padding: 5px 16px 16px;
    display: flex;
    justify-content: center;
    background-color: white;
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.12);
    width: 110px;
    float: left;
    z-index: 1;
  }
  .actions {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    li {
      cursor: pointer;
    }
  }
}
