@import '@/styles/artifacts.scss';

















































































































































































































































.label {
  white-space: pre-line;
  word-break: break-all;
}
.text-light {
  color: colorVodafone(grey);
}
.text-overflow {
  @include textOverflow();
}
