@import '@/styles/artifacts.scss';





















.cell {
  flex: 1 0 0;
  box-sizing: border-box;
  max-width: 100%;
  padding: 10px;

  &.push-right {
    margin-left: auto;
  }

  &.text-overflow {
    @include textOverflow();
  }
}
