@import '@/styles/artifacts.scss';


















.image {
  width: 20px;
  height: 10px;
}
.GREEN .icon .fill {
  fill: colorVodafone(digital-green);
}
.GREEN .icon .stroke {
  stroke: colorVodafone(digital-green);
}
.RED .icon .fill {
  fill: colorVodafone(vodafone-red);
}
.RED .icon .stroke {
  stroke: colorVodafone(voadfone-red);
}
