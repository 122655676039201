@import '@/styles/artifacts.scss';






































.mini-sensor {
  display: block;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.22);
  border-radius: 6px;
  background-color: colorVodafone(white);
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.mini-sensor.alert {
  border: 2px solid colorVodafone(alert-severity-high);
}

.layout {
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  border: 2px solid transparent;
}
.mini-sensor.selected {
  .location {
    color: colorVodafone(vodafone-red);
  }
  .layout {
    border-bottom: 2px solid colorVodafone(vodafone-red);
  }
}
.mini-sensor.no-metrics {
  opacity: 0.5;
  .image {
    opacity: 0.5;
  }
}
.mini-sensor:hover .location {
  color: colorVodafone(vodafone-red);
}
.image {
  display: block;
  margin: auto;
  max-width: 100%;
  // Leave space for the subtitle
  max-height: 75%;
}
.location {
  margin-top: auto;
  hyphens: auto;
  font-size: fontSize(fs-50);
}
