@import '@/styles/artifacts.scss';





















.item {
  font-size: fontSize(fs-base);
  line-height: lineHeight(lh-50);
  overflow-wrap: break-word;
}
.label {
  padding: 0;
  width: 45%;
  text-align: right;
}
.value {
  box-sizing: border-box;
  width: 62%;
  padding: 0 0 0 5px;
  color: colorVodafone(grey);
}
