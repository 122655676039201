@import '@/styles/artifacts.scss';

























































































































































































































































































































































.core-draggable-tree-node-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.indent {
  padding-left: 0.5em;
}

.tree-node.active,
.tree-node:not(.disabled):hover {
  color: colorVodafone(vodafone-red);
}
.tree-node:first-of-type {
  .name {
    margin: 0;
  }
}
.name {
  font-size: fontSize(fs-100);
  line-height: lineHeight(lh-50);
  font-weight: 700;
  color: colorVodafone(black);
}
.icon,
.name {
  margin-top: 8px;
}
