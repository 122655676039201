@import '@/styles/artifacts.scss';






















@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  border-radius: 50%;
  border: 1px solid #ccc;
  border-top-color: colorVodafone(vodafone-red);
  animation: spinner 0.6s linear infinite;
}

.iframe-container {
  height: 100%;
}
.iframe {
  border: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 300ms;
}
.iframe.loaded {
  opacity: 1;
}
