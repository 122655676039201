@import '@/styles/artifacts.scss';
















































































.layout {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  .cars {
    display: flex;
    flex-wrap: nowrap;
    padding: 0 16px;
    height: 100%;
    flex: 1;
    min-height: 120px;
    .car {
      background-image: url('./kone-lift-lg.svg');
      background-repeat: no-repeat;
      background-position: center;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

.status {
  &.standing {
    color: colorVodafone(dark-grey);
  }
  &.moving {
    color: colorVodafone(digital-green-active);
  }
}

.load-state {
  margin-bottom: 70px;
  .load-state-icon {
    width: 16px;
    height: 16px;
  }
}

.moving-direction {
  width: 10px;
  height: 10px;
  padding-left: 4px;
  vertical-align: middle;
}
