@import '@/styles/artifacts.scss';




















.layout {
  min-height: 100vh;
}

.layout-enter-active,
.layout-leave-active {
  transition: opacity 300ms;
}
.layout-enter,
.layout-leave-to {
  opacity: 0;
}
